import React from "react"

export default ({ ...rest }) => (
  <svg
    width="220"
    height="220"
    viewBox="0 0 220 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#docs-tutorial-cta-bg-clip0)">
      <path
        d="M181.297 154.22H129.229L155.263 108.318L181.297 63.1006L208.016 108.318L234.05 154.22H181.297Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.551 87.1719V132.156H58.3164C62.1975 106.754 84.0896 87.2791 110.551 87.1719Z"
        fill="#F67300"
      />
      <rect
        x="23.7252"
        y="45.3297"
        width="86.3011"
        height="86.3011"
        stroke="#FFB238"
        strokeWidth="1.05"
      />
      <circle
        cx="110.771"
        cy="140.237"
        r="53.0658"
        stroke="#FFB238"
        strokeWidth="1.05"
      />
      <path
        d="M206.9 219.7C205.4 178.7 150.66 95.4561 149.674 93.7332C148.688 92.0102 142.391 76.9375 142.391 76.9375C142.391 76.9375 140.685 73.1436 137.798 74.8328C134.158 76.5828 129.853 86.896 129.853 86.896C129.853 86.896 128.479 92.1748 131.148 92.1748C133.108 92.1048 132.023 98.6352 132.023 98.6352L132.233 111.746C132.233 111.746 124.498 108.085 120.543 107.406C117.245 106.84 113.385 108.249 113.035 109.719L126.003 117.346L130.506 120.277C130.506 120.277 137.798 126.2 139.665 129.319C141.532 132.438 172.9 187.7 175.4 219.7"
        fill="white"
      />
      <path
        d="M135.451 112.096C135.398 112.096 135.346 112.09 135.294 112.078L107.932 105.796C107.932 105.796 107.842 105.795 107.827 105.795L102.667 108.515C102.565 108.569 102.453 108.596 102.341 108.596C102.19 108.596 102.039 108.547 101.915 108.451C101.697 108.284 101.597 108.007 101.658 107.74L108.518 77.6402C108.559 77.459 108.671 77.3018 108.828 77.203C108.941 77.1323 109.07 77.0957 109.201 77.0957C109.253 77.0957 109.305 77.1015 109.356 77.1131L141.556 84.4631C141.933 84.5493 142.169 84.9239 142.083 85.3005L136.133 111.551C136.092 111.732 135.98 111.889 135.823 111.988C135.711 112.059 135.581 112.096 135.451 112.096Z"
        fill="white"
      />
      <path
        d="M107.399 105.7C107.469 105.63 107.759 105.7 107.899 105.7C107.969 105.7 108.329 105.7 108.399 105.7L135.899 112.2L140.399 92.7002L103.031 103.486L101.899 108.2H102.899L107.399 105.7Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M135.451 112.096C135.398 112.096 135.346 112.09 135.294 112.078L107.932 105.796C107.932 105.796 107.842 105.795 107.827 105.795L102.667 108.515C102.565 108.569 102.453 108.596 102.341 108.596C102.19 108.596 102.039 108.547 101.915 108.451C101.697 108.284 101.597 108.007 101.658 107.74L108.518 77.6402C108.559 77.459 108.671 77.3018 108.828 77.203C108.941 77.1323 109.07 77.0957 109.201 77.0957C109.253 77.0957 109.305 77.1015 109.356 77.1131L141.556 84.4631C141.933 84.5493 142.169 84.9239 142.083 85.3005L136.133 111.551C136.092 111.732 135.98 111.889 135.823 111.988C135.711 112.059 135.581 112.096 135.451 112.096Z"
        stroke="black"
        strokeOpacity="0.1"
      />
      <path
        d="M101.29 113.426C101.22 113.216 101.01 113.146 100.8 113.146C100.59 113.146 100.38 113.286 100.31 113.426C99.9601 114.126 96.8101 120.285 98.4901 122.805C98.8401 123.296 99.5401 123.926 100.87 123.926C100.94 123.926 101.01 123.926 101.08 123.926C102.41 123.855 103.11 123.155 103.39 122.596C104.86 120.005 101.64 114.126 101.29 113.426Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M132.229 111.745C132.229 111.745 124.494 108.085 120.539 107.405C117.242 106.839 113.381 108.248 113.031 109.718L125.999 117.345L130.502 120.276C130.502 120.276 136.418 124.738 139.661 129.319C139.661 129.319 170.899 183.7 175.899 219.2"
        stroke="black"
        strokeOpacity="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.246 79.4001C119.246 79.4001 115.833 75.2001 112.981 76.2563C110.64 77.1231 106.401 81.118 105.421 82.798C104.441 84.408 103.787 86.1522 104.371 86.7563C106.08 88.5266 114.381 80.8763 114.381 80.8763"
        fill="white"
      />
      <path
        d="M119.246 79.4001C119.246 79.4001 115.833 75.2001 112.981 76.2563C110.64 77.1231 106.401 81.118 105.421 82.798C104.441 84.408 103.787 86.1522 104.371 86.7563C106.08 88.5266 114.381 80.8763 114.381 80.8763"
        stroke="black"
        strokeOpacity="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M126.84 77.0249C126.84 77.0249 123.383 75.8079 121.666 76.3977C119 77.3132 112.615 87.9533 112.965 89.4233C113.315 90.8933 114.66 90.2549 115.22 89.9749C115.78 89.6948 124.95 81.9249 124.95 81.9249"
        fill="white"
      />
      <path
        d="M126.84 77.0249C126.84 77.0249 123.383 75.8079 121.666 76.3977C119 77.3132 112.615 87.9533 112.965 89.4233C113.315 90.8933 114.66 90.2549 115.22 89.9749C115.78 89.6948 124.95 81.9249 124.95 81.9249"
        stroke="black"
        strokeOpacity="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M136.396 76.4683C136.396 76.4683 132.483 72.922 129.377 74.3309C125.645 76.0235 122.171 89.8588 122.133 91.3433C122.063 94.1432 123.411 95.0157 124.671 94.5257C125.931 94.0357 131.233 84.2827 131.233 84.2827"
        fill="white"
      />
      <path
        d="M136.396 76.4683C136.396 76.4683 132.483 72.922 129.377 74.3309C125.645 76.0235 122.171 89.8588 122.133 91.3433C122.063 94.1432 123.411 95.0157 124.671 94.5257C125.931 94.0357 131.233 84.2827 131.233 84.2827"
        stroke="black"
        strokeOpacity="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M142.389 76.9375C142.389 76.9375 140.683 73.1436 137.796 74.8328C134.156 76.5828 129.851 86.896 129.851 86.896C129.851 86.896 128.477 92.1748 131.146 92.1748C133.106 92.1048 138.933 80.7123 138.933 80.7123"
        fill="white"
      />
      <path
        d="M138.933 80.7123C138.933 80.7123 133.106 92.1048 131.146 92.1748C128.477 92.1748 129.851 86.896 129.851 86.896C129.851 86.896 134.156 76.5828 137.796 74.8328C140.683 73.1436 142.389 76.9375 142.389 76.9375C145.059 83.0251 150.899 96.0002 152.899 99.2002C155.399 103.2 206.899 183.2 206.899 220.7"
        stroke="black"
        strokeOpacity="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="docs-tutorial-cta-bg-clip0">
        <rect width="220" height="220" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
