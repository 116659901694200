import React from "react"
import { Link, hexToRGBA } from "gatsby-interface"

import { mediaQueries } from "../../utils/presets"
import QuickStartIllustration from "../../assets/QuickStartIllustration"
import TutorialIllustration from "../../assets/TutorialIllustration"

const sectionCardStylesGenerator = variant => theme => ({
  background:
    variant === "PRIMARY"
      ? theme.colors.purple[60]
      : theme.colors.orange[60],
  borderRadius: theme.radii[3],
  boxShadow:
    variant === "PRIMARY"
      ? `0px 2px 4px ${hexToRGBA(
          theme.colors.purple[60],
          0.08,
          true
        )}, 0px 4px 8px ${hexToRGBA(theme.colors.purple[50], 0.16, true)}`
      : `0px 2px 4px ${hexToRGBA(
          theme.colors.orange[70],
          0.16,
          true
        )}, 0px 4px 8px ${hexToRGBA(theme.colors.orange[60], 0.16, true)}`,
  color:
    variant === "PRIMARY"
      ? theme.colors.whiteFade[90]
      : theme.colors.blackFade[90],
  display: `inline-flex`,
  flexDirection: "column",
  alignItems: "flex-start",
  overflow: `hidden`,
  padding: theme.space[7],
  position: `relative`,
  textDecoration: `none`,
  transition: `box-shadow ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
  "&&:hover, &&:focus": {
    boxShadow:
      variant === "PRIMARY"
        ? `0px 4px 16px ${hexToRGBA(
            theme.colors.purple[60],
            0.08,
            true
          )}, 0px 8px 24px ${hexToRGBA(
            theme.colors.purple[50],
            0.16,
            true
          )}`
        : `0px 4px 16px ${hexToRGBA(
            theme.colors.orange[70],
            0.08,
            true
          )}, 0px 8px 24px ${hexToRGBA(
            theme.colors.orange[60],
            0.16,
            true
          )}`,
    color:
      variant === "PRIMARY"
        ? theme.colors.whiteFade[90]
        : theme.colors.blackFade[90],
    textDecoration: `none`,
    ".cta-card-button": {
      background: theme.colors.blackFade[50],
    },
  },
})

const headerStylesGenerator = variant => theme => ({
  color: variant === "PRIMARY" ? "white" : theme.colors.blackFade[90],
  marginBottom: theme.space[1],
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[5],
  [mediaQueries.desktop]: {
    fontSize: theme.fontSizes[4],
  },
})

const containerStyles = theme => ({
  marginBottom: theme.space[10],
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[5],
  [mediaQueries.desktop]: {
    gridTemplateColumns: `1fr 1fr`,
    gridGap: theme.space[10],
  },
})

const illustrationStyles = {
  position: `absolute`,
  right: 0,
  top: "50%",
  transform: "translateY(-50%)",
  opacity: 0.25,
  [mediaQueries.hd]: {
    opacity: 1,
  },
}

const ctaDescriptionStyles = theme => ({
  fontSize: theme.fontSizes[2],
  marginBottom: theme.space[4],
  [mediaQueries.hd]: {
    width: `70%`,
  },
})

const ctaCardButtonStyles = theme => ({
  background: theme.colors.blackFade[40],
  borderRadius: theme.radii[2],
  color: theme.colors.white,
  fontWeight: theme.fontWeights.semiBold,
  display: `inline-block`,
  marginRight: "auto",
  paddingLeft: theme.space[5],
  paddingRight: theme.space[5],
  paddingTop: theme.space[3],
  paddingBottom: theme.space[3],
  marginTop: "auto",
  transition: `background ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
})

const textContainerStyles = {
  height: "100%",
  width: "100%",
  position: "relative",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
}

const SectionCallout = ({ section, variant = "PRIMARY" }) => (
  <Link
    to={section?.ctaLink}
    css={ sectionCardStylesGenerator(variant) }
  >
    <div
      css={textContainerStyles}
    >
      <h2
        css={headerStylesGenerator(variant)}
      >
        {section?.title}
      </h2>
      <div
        css={ctaDescriptionStyles}
      >
        {section?.description}
      </div>
      <div
        className="cta-card-button"
        css={ctaCardButtonStyles}
      >
        {section?.ctaLabel}
      </div>
    </div>
    {variant === "PRIMARY" ? (
      <QuickStartIllustration css={illustrationStyles} />
    ) : (
      <TutorialIllustration css={illustrationStyles} />
    )}
  </Link>
)

const DocsHomeCtaCallout = ({ content }) => {
  return (
    <section css={theme => containerStyles(theme)}>
      <SectionCallout section={content[0]} />
      <SectionCallout section={content[1]} variant="SECONDARY" />
    </section>
  )
}

export default DocsHomeCtaCallout
